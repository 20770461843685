.countdown-component {

    .its-time {
        font-size: 60px;

        @media screen and (max-width: 900px) {
            font-size: 40px;
        }

        @media screen and (max-width: 768px) {
            font-size: 30px;
        }
    }

    .timer-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .count-dot {
            margin-top: -20px;
        }


        .timer-block {
            text-align: center;

            .time {
                font-size: 90px;
                line-height: 90px;
                font-weight: 600;

                @media screen and (max-width: 900px) {
                    font-size: 50px;
                    line-height: 50px;
                    font-weight: 900;
                }

                @media screen and (max-width: 768px) {
                    font-size: 30px;
                    line-height: 30px;
                    font-weight: 900;
                }

            }

            .description {
                font-size: 14px;
                line-height: 30px;

                @media screen and (max-width: 900px) {
                    font-size: 12px;
                    line-height: 20px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 10px;
                    line-height: 16px;
                }
            }
        }
    }
}