.login-container {
    min-height: 100vh;

    .inner {
        margin: 30px;
        vertical-align: middle;
        display: grid;
        align-items: center;
    }

    .vertical-middle {
        display: grid;
        align-items: center;
    }

    .height-100 {
        height: calc(100% - 20px);
    }

    .first-section {
        .heading-text {
            text-align: center;
            font-size: 160px;
            font-weight: 100;

            @media screen and (max-width: 900px) {
                font-size: 140px;
            }

            @media screen and (max-width: 768px) {
                font-size: 80px;
            }
        }
    }


    .seventh-section {
        .heading {
            text-align: center;
            font-size: 60px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 40px;
            }

            @media screen and (max-width: 768px) {
                font-size: 30px;
            }
        }

        form {
            min-width: 100% !important;

            .form-floating {
                margin-top: 20px;
                min-width: 100% !important;

                input,
                textarea {
                    border: 0.5px solid #000;
                    font-size: 16px;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid #000;
                    }

                    @media screen and (max-width: 900px) {
                        font-size: 14px;
                    }
        
                    @media screen and (max-width: 768px) {
                        font-size: 12px;
                    }
                }
            }

            .form-button {
                margin-top: 20px;
                text-align: center;
            }
        }
    }
}