.footer-component {
    //margin-top: 50px;
    padding: 20px;

    .title {
        font-size: 40px;
        font-weight: 100;

        @media screen and (max-width: 900px) {
            font-size: 34px;
        }

        @media screen and (max-width: 768px) {
            font-size: 26px;
        }
    }

    .links {
        max-width: 600px;
        width: 100%;

        a {
            font-size: 20px;
            color: #000;
            text-decoration: none;
            display: block;
            margin-bottom: 10px;
            font-weight: 100;
            line-height: 20px;
            border-bottom: 0.75px solid #000;
            -webkit-text-stroke: 0.4px white;
            -moz-text-stroke: 0.4px white;
            -ms-text-stroke: 0.4px white;
            -o-text-stroke: 0.4px white;
            text-stroke: 0.4px white;

            @media screen and (max-width: 900px) {
                font-size: 16px;
                -webkit-text-stroke: 0.3px white;
                -moz-text-stroke: 0.3px white;
                -ms-text-stroke: 0.3px white;
                -o-text-stroke: 0.3px white;
                text-stroke: 0.3px white;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
                -webkit-text-stroke: 0.2px white;
                -moz-text-stroke: 0.2px white;
                -ms-text-stroke: 0.2px white;
                -o-text-stroke: 0.2px white;
                text-stroke: 0.2px white;
            }

            &:hover {
                -webkit-text-stroke: 0px white;
                border-bottom: 1px solid #000;
            }
        }
    }
}