.rsvps-container {
    min-height: 100vh;
    max-width: 100vw;

    .inner {
        margin: 30px;
        vertical-align: middle;
        display: grid;
        align-items: center;
    }

    .vertical-middle {
        display: grid;
        align-items: center;
    }

    .height-100 {
        height: calc(100% - 20px);
    }

    .first-section {
        .heading-text {
            text-align: center;
            font-size: 160px;

            @media screen and (max-width: 900px) {
                font-size: 140px;
            }

            @media screen and (max-width: 768px) {
                font-size: 80px;
            }
        }
    }

    .seventh-section {
        .heading {
            text-align: center;
            font-size: 60px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 40px;
            }

            @media screen and (max-width: 768px) {
                font-size: 30px;
            }
        }
    }
}