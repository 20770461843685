.save-the-date-page {
    min-height: 100vh;  
    position: relative;
    
    .center-text {
        position: absolute;
        font-size: 240px;
        top: 40%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;

        @media screen and (max-width: 900px) {
            font-size: 190px;
        }

        @media screen and (max-width: 768px) {
            font-size: 140px;
        }
    }

    .side-text {
        position: absolute;
        font-size: 22px;
        top: 100px;
        left: -50px;
        transform: rotate(-90deg);

        @media screen and (max-width: 900px) {
            font-size: 16px;
            line-height: 24px;
            top: 70px;
            left: -40px;
        }

        @media screen and (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
            top: 50px;
            left: -30px;
        }
    }

    .heading-text {
        position: absolute;
        font-size: 30px;
        line-height: 40px;
        top: 70%;
        transform: translateY(-50%);
        width: 80%;
        text-align: center;
        transform: translateX(10%);

        @media screen and (max-width: 900px) {
            font-size: 18px;
            line-height: 28px;
        }

        @media screen and (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .sub-heading-text {
        position: absolute;
        font-size: 22px;
        top: calc(70% + 100px);
        transform: translateY(-50%);
        width: 80%;
        text-align: center;
        transform: translateX(10%);

        @media screen and (max-width: 900px) {
            font-size: 16px;
            line-height: 28px;
            top: calc(70% + 70px);
        }

        @media screen and (max-width: 768px) {
            font-size: 10px;
            top: calc(65% + 100px);
        }
    }
}

