.home-container {
    min-height: 100vh;

    .inner {
        margin: 30px;
        vertical-align: middle;
        display: grid;
        align-items: center;

        @media screen and (max-width: 900px) {
            margin: 28px;
        }

        @media screen and (max-width: 768px) {
            margin: 24px;
        }
    }

    .vertical-middle {
        display: grid;
        align-items: center;
    }

    .height-100 {
        height: calc(100% - 20px);
    }

    .first-section {
        position: relative;
        margin-top: 20px;
        padding-top: 120px;
        padding-bottom: 120px;
        transition: 0.2s;

        @media screen and (max-width: 900px) {
            margin-top: 15px;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        @media screen and (max-width: 768px) {
            margin-top: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .heading-text {
            text-align: center;
            font-size: 160px;
            font-weight: 100;

            @media screen and (max-width: 900px) {
                font-size: 140px;
            }

            @media screen and (max-width: 768px) {
                font-size: 80px;
            }

        }

        &:before {
            margin: 0px;
            margin-left: 0px;
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% - 0px);
            height: calc(100% - 20px);
            opacity: 1;
            background-image: url('../images/couple2Black.jpg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            z-index: -1;

            @media screen and (max-width: 900px) {
                padding-top: 20px;
                height: calc(100% - 14px);
            }

            @media screen and (max-width: 768px) {
                height: calc(100% - 0px);
            }
        }

        &:after {
            margin: 0px;
            margin-left: 0px;
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% - 0px);
            height: calc(100% - 0px);
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.0);
            z-index: -1;
        }
    }

    .second-section {
        //position: sticky;
        //position: -webkit-sticky;
        //top: 0;
        //z-index: 999;
        background-color: #fff;
        
        .heading {
            text-align: center;
            font-size: 120px;
            font-weight: 100;

            @media screen and (max-width: 900px) {
                font-size: 84px;
            }

            @media screen and (max-width: 768px) {
                font-size: 56px;
            }

            .plus-sign {
                font-size: 40px;
                padding-top: 60px;

                @media screen and (max-width: 900px) {
                    font-size: 38px;
                    padding-top: 36px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 26px;
                    padding-top: 24px;
                }
            }
        }

        .sub-heading {
            text-align: center;
            font-size: 20px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }

    .third-section {
        .heading {
            text-align: center;
            font-size: 20px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        .sub-heading {
            text-align: center;
            font-size: 20px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        .number {
            font-size: 100px;
            line-height: 100px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 80px;
                line-height: 80px;
            }

            @media screen and (max-width: 768px) {
                font-size: 60px;
                line-height: 60px;
            }
        }

        .text {
            font-size: 20px;
            letter-spacing: 2px;
            text-align: justify;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }

    .fourth-section {
        .text {
            font-size: 20px;
            letter-spacing: 2px;
            text-align: justify;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        .heading {
            text-align: center;
            font-size: 20px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        .color-icon {
            width: 60px;
            height: 60px;
            margin: 10px;
            border-radius: 50px;
            background-color: #383b26;

            @media screen and (max-width: 900px) {
                height: 45px;
                width: 45px;
                margin: 9px;
            }

            @media screen and (max-width: 768px) {
                width: 45px;
                height: 45px;
                margin: 2.5px;
            }
        }
    }

    .fifth-section {

        .text {
            font-size: 20px;
            letter-spacing: 2px;
            text-align: justify;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }

    .fifth-section {
        .heading {
            text-align: center;
            font-size: 60px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 40px;
            }

            @media screen and (max-width: 768px) {
                font-size: 30px;
            }
        }

        form {
            min-width: 100% !important;

            .form-floating {
                font-size: 20px;
                margin-top: 20px;
                min-width: 100% !important;

                @media screen and (max-width: 900px) {
                    font-size: 16px;
                    margin-top: 14px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    margin-top: 10px;
                }

                input,
                textarea {
                    border: 0.5px solid #000;
                    font-size: 16px;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid #000;
                    }

                    @media screen and (max-width: 900px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: 768px) {
                        font-size: 12px;
                    }
                }
            }

            .form-button {
                margin-top: 26px;
                text-align: center;

                @media screen and (max-width: 900px) {
                    font-size: 20px;
                    margin-top: 18px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 18px;
                    margin-top: 14px;
                }
            }

            .form-thanks {
                text-align: center;
                font-size: 20px;
                letter-spacing: 2px;

                @media screen and (max-width: 900px) {
                    font-size: 18px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }

            .form-tabs {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;

                @media screen and (max-width: 900px) {
                    font-size: 16px;
                    margin-top: 14px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    margin-top: 10px;
                }

                .form-tab {
                    width: calc(50% - 10px);
                    text-align: center;
                    border: 0.5px solid #000;
                    border-radius: 5px;
                    padding: 15px;
                    cursor: pointer;

                    @media screen and (max-width: 900px) {
                        width: calc(50% - 7px);
                    }

                    @media screen and (max-width: 768px) {
                        width: calc(50% - 5px);
                    }

                    &:hover {
                        background-color: #000;
                        color: #fff;
                    }

                    &.active {
                        background-color: #000;
                        color: #fff;
                    }

                    &:active {
                        transform: scale(0.99);
                        background-color: #000 !important;
                        color: #fff !important;
                        border: 0.5px solid #000 !important;
                    }
                }
            }
        }


    }

    .sixth-section {
        .heading {
            text-align: center;
            font-size: 20px;
            letter-spacing: 2px;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        .text {
            font-size: 20px;
            letter-spacing: 2px;
            text-align: justify;

            @media screen and (max-width: 900px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
}