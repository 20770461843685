@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');

body {
    font-size: 18px;
    font-family: "Hiragino", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 0px;
    margin: 0px;
    max-width: 100vw;
}

.container-fluid {
    padding: 0px;
    margin: 0px;

}

.divider-x {
    border-bottom: 0.75px solid #000;

    @media screen and (max-width: 900px) {
        border-bottom: 0.5px solid #000;
    }

    @media screen and (max-width: 768px) {
        border-bottom: 0.5px solid #000;
    }

}

.divider-x-top {
    border-top: 0.75px solid #000;

    @media screen and (max-width: 900px) {
        border-bottom: 0.5px solid #000;
    }

    @media screen and (max-width: 768px) {
        border-bottom: 0.5px solid #000;
    }
}

.divider-y {
    border-left: 0.75px solid #000;

    @media screen and (max-width: 768px) {
        border-left: none;
    }
}

.mobile-divider-x {
    display: none;
    margin-top: 20px;
    margin-bottom: 20px;

    div {
        border-bottom: 0.75px solid #000;
        width: 100vw;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;

        @media screen and (max-width: 900px) {
            border-bottom: 0.5px solid #000;
        }

        @media screen and (max-width: 768px) {
            border-bottom: 0.5px solid #000;
        }

    }

    @media screen and (max-width: 768px) {
        display: block;
    }
}

.hurricane-regular {
    font-family: "Hurricane", cursive;
    font-weight: 400;
    font-style: normal;
}

.hiragino-regular {
    font-family: "Hiragino", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.light {
    font-weight: 100;
    -webkit-text-stroke: 0.4px white;
    -moz-text-stroke: 0.4px white;
    -ms-text-stroke: 0.4px white;
    -o-text-stroke: 0.4px white;
    text-stroke: 0.4px white;

    @media screen and (max-width: 900px) {
        -webkit-text-stroke: 0.3px white;
        -moz-text-stroke: 0.3px white;
        -ms-text-stroke: 0.3px white;
        -o-text-stroke: 0.3px white;
        text-stroke: 0.3px white;
    }

    @media screen and (max-width: 768px) {
        -webkit-text-stroke: 0.2px white;
        -moz-text-stroke: 0.2px white;
        -ms-text-stroke: 0.2px white;
        -o-text-stroke: 0.2px white;
        text-stroke: 0.2px white;
    }




}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.bold {
    font-weight: 700;
}

.black {
    font-weight: 900;
}

.italic {
    font-style: italic;
}

.btn-primary {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s;
    border: 0.5px solid #000;

    &:hover {
        background-color: #000;
        color: #fff;
        border: 0.5px solid #000;
    }

    &:focus {
        outline: none;
        background-color: #000;
        color: #fff;
    }

    &:active {
        transform: scale(0.95);
        background-color: #000 !important;
        color: #fff !important;
        border: 0.5px solid #000 !important;
    }

    &:disabled {
        background-color: #ccc;
        color: #fff;
        cursor: not-allowed;
    }
}

.btn-secondary {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s;
    border: 0.5px solid #000;

    &:hover {
        background-color: #fff;
        color: #000;
        border: 0.5px solid #000;
    }

    &:focus {
        outline: none;
        background-color: #fff;
        color: #000;
    }

    &:active {
        transform: scale(0.95);
        background-color: #000 !important;
        color: #fff !important;
        border: 0.5px solid #000 !important;
    }

    &:disabled {
        background-color: #ccc;
        color: #fff;
        cursor: not-allowed;
    }
}

.shake {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

.hover-shake {
    cursor: pointer;

    &:hover {
        animation: shake 0.5s;
        animation-iteration-count: infinite;
    }
}


@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}